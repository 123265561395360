.result-background{
    width: 100%;
    height: 100vh;
    background: darkcyan;
    display: flex;
    align-items: center;
    justify-content: center;
}
.result-background .box{
    width: 70%;
    height: 500px;    
    border-radius: 3px;
    background: white;
    overflow: hidden;
    box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.72),
                1px -1px 5px rgba(0, 0, 0, 0.72),
                2px 0px 3px rgba(0, 0, 0, 0.72);
}
.result-background .box .title{
    width: 100%;
    height: 55px;
    display: grid;
    place-items: center;
    background: darkcyan;
}
.result-background .box .title h2{
    font-size: 22px;
    color:rgba(245, 245, 245, 0.919);
    font-weight: 599;
}
.result-background .box .details{
    width: 100%;
    padding: 15px 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:15px;
}
.result-background .box .details .item{
    width: 200px;    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:5px;
}
.result-background .box .details .item button{
    padding: 5px 15px;
    background: darkcyan;
    border:2px solid darkcyan;
    cursor: pointer;
    color:rgba(255, 255, 255, 0.784);
    border-radius: 4px;
    transition: all ease 0.4s;
}
.result-background .box .details .item button:hover{
    color:white;
}
.result-background .box .details .item label{
    font-size: 14px;
    color:rgba(0, 0, 0, 0.82);
    font-weight: bold;
    height: 25px;
}
.result-background .box .details .item p{
    font-size: 15px;
    font-weight: bold;
}
.result-background table{    
    margin: 0px auto;
    width: 90%;    
    border-collapse: collapse;
    border:2px solid darkcyan
}
.result-background .table-title{
    width: 90%;
    margin: 10px auto 0px auto;
    background: darkcyan;
    height: 60px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: grid;
    place-items: center;
}
.result-background .table-title h2{
    font-size: 22px;
    color:white;
}
.result-background table thead th{
    background: darkcyan;
    font-size: 15px;
    font-weight: 599;
    padding: 10px;
    color: rgba(255, 255, 255, 0.824);
}
.result-background table tbody tr{
    border-bottom: 2px solid darkcyan;
}
.result-background table tbody tr:nth-child(even){
    background: lightblue;
}
.result-background table tbody td{
    padding: 8px;
    text-align: center;
    font-size: 14px;
}
