.background1{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
}
.loading-circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: 200;
}

.loading-circle {
    width: 80px;
    height: 80px;
    border: 5px solid #f3f3f3; 
    border-top: 6px solid blue; 
    border-radius: 50%;
    animation: spin 1.4s linear infinite;
    z-index: 300;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}