.actual-container .card{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 400px;
    height: 200px;
    background: white;
    box-shadow: 0px 2px 8px blue;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:20px;
}
.actual-container .card h2{
    width: 100%;
    font-size: 20px;
    text-align: center;
    font-weight: 599;
}
.actual-container .card button{
    padding: 10px 20px;
    background: blue;
    border-radius: 4px;
    color:white;
    outline: none;
    border:2px solid blue;
    cursor: pointer;
}
.questions-header{
    width: 100%;
    height: 60px;
    background: white;
    box-shadow: 2px 2px 8px black;
    display: flex;
    align-items: center;
    justify-content: center;
}
.questions-header h2 {
    font-size: 20px;    
    color: blueviolet;
    font-weight: 599;
}

@keyframes anim {
    0% {
        background-position: 100% 0; 
    }
    100% {
        background-position: 0 0; 
    }
}
.question-container{
    width: 100%;    
    margin-top: 20px;
    height: calc(100vh - 100px);    
}
.question-container .section-details{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 5%;
}
.question-container .section-details h2{
    font-size: 22px;
    font-weight: 600;
    font-family: 'Ubuntu';
    
}
.question-container .questions{
    width: 100%;
    padding: 30px 5%;
    height: calc(100% - 80px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.question-container .questions .question-details{
    width: 100%;
    height: 100%;
    padding: 20px 0px;
    position: relative;
}
.question-container .questions .question-details .actions-question{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.question-container .questions .question-details .actions-question button{
    width: 180px;
    height: 50px;
    outline: none;
    cursor: pointer;
    transition: alle ase 0.3s;
    font-size: 15px;
    font-weight: 599;
    border-radius: 3px;
    margin-right: 20px;
    transition: all ease 0.4s;
}
.question-container .questions .question-details .actions-question button:nth-child(1){
    background: red;
    color:white;
    border:2px solid red
}
.question-container .questions .question-details .actions-question button:nth-child(2){
    background: blue;
    color:white;
    border:2px solid blue
}
.question-container .questions .question-details .actions-question button:nth-child(1):disabled{
    background: rgba(255, 0, 0, 0.543);
    color:white;
    border:2px solid red
}
.question-container .questions .question-details .actions-question button:nth-child(2):disabled{
    background: rgba(0, 0, 255, 0.66);
    color:white;
    cursor: not-allowed;
    border:2px solid blue
}
.question-container .questions .question-details  .user-details{
    width: 100%;
    position: absolute;
    bottom: 80px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:10px;
}
.question-container .questions .question-details  .user-details span{
    padding:8px 15px;
    font-weight: bold;
    border-radius: 3px;
}
.question-container .questions .question-details .user-details p:nth-child(2){
    color:blue;
    width: 200px;
}
.question-container .questions .question-details .user-details p:nth-child(1){
    color:crimson;
    width: 200px;
}
.question-container .questions .question-details  .user-details p:nth-child(2) span{
    background: blue;
    color:white;
}
.question-container .questions .question-details  .user-details p:nth-child(1) span{
    background: crimson;
    color:white;
}
.question-container .questions .question-details .actions-question .tags{
    display: flex;
    align-items: center;
    justify-content: start;
    gap:8px;
}
.question-container .questions .question-details .actions-question .tags p{
    padding: 8px 10px;
    background: rgba(0, 0, 0, 0.8);
    color:white;
    border:1px solid rgba(0, 0, 0, 0.8);
    border-radius: 3px;
}
.question-container .questions .question-details .options{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:20px 30px;
    flex-wrap: wrap;
    margin-top: 20px;
}
.question-container .questions .question-details .options .option{
    width: 48%;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:8px;
    
}
.custom-radio {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:5px;
    cursor: pointer;
}

.custom-radio input[type="radio"] {
    display: none;
}
.checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 1.3em;
    width: 1.3em;
    background-color: #2196F300;
    border-radius: 0.25em;
    transition: all 0.25s;
  }
  
  /* When the checkbox is checked, add a blue background */
  .option input:checked ~ .checkmark {
    background-color: blue;
  }
  .option input:checked ~ .checkmark + p{
    color: blue;
    font-weight: bold;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    transform: rotate(0deg);
    border: 0.1em solid black;
    left: 0;
    top: 0;
    width: 1.05em;
    height: 1.05em;
    border-radius: 0.25em;
    transition: all 0.25s, border-width 0.1s;
  }
  
  /* Show the checkmark when checked */
  .option input:checked ~ .checkmark:after {
    left: 0.45em;
    top: 0.25em;
    width: 0.25em;
    height: 0.5em;
    border-color: #fff0 white white #fff0;
    border-width: 0 0.15em 0.15em 0;
    border-radius: 0em;
    transform: rotate(45deg);
  }
.radio-btn {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.8);
    position: relative;
    margin-right: 8px;      
}

.radio-btn::before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.8);    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s ease;
}

input[type="radio"]:checked + .radio-btn::before {
    content: '✓';    
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.8);    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(20deg);
    opacity: 1;
    transition: opacity 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;   
    
    
}

.custom-radio p {
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: start;
    gap:10px;
}

.question-container .questions .question-no{
    width: 30%;
    height: 100%;    
    border-radius: 4px;
    overflow: hidden;
}
.question-container .questions .question-no .title{
    width: 100%;
    height: 50px;
    background: rgba(0, 0, 0, 0.8);
    color:white;
    display: grid;
    place-content: center;    
}
.question-container .questions .question-no .title h2{
    font-size: 18px;
    font-weight: 599;
}
.question-container .questions .question-no .sequence{    
    padding: 20px 50px;
    margin: 0px auto;    
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    gap:20px 30px;
    border:2px solid rgba(0, 0, 0, 0.8);
    
    overflow: hidden;
}
.question-container .questions .question-no .sequence .circle{
    width: 50px;
    height: 50px;
    display: grid;
    place-content: center;
    background: white;
    color:rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    transition: all ease-in 0.4s;
    border:2px solid rgba(0, 0, 0, 0.8)
}
.question-container .questions .question-no .sequence .done{
    width: 50px;
    height: 50px;
    display: grid;
    place-content: center;
    background: rgba(0, 0, 0, 0.8);
    color:white;
    border-radius: 50%;
    transition: all ease-in 0.4s;
}
.question-container .questions .question-no .sequence .selected{
    width: 50px;
    height: 50px;
    display: grid;
    place-content: center;
    background: blueviolet;
    color:white;
    border-radius: 50%;
    transition: all ease-in 0.4s;
}
.question-container .footer2{
    width: 100%;
    height: 60px;
    height: 50px;
    background: blue;
    color:white;
    display: flex;
    padding: 0px 5%;
    align-items: center;
    justify-content: start;
    gap:20px;
}
.question-container .footer2 h2{
    font-size: 16px;
    font-weight: 599;

}