.title-container{
    margin: 20px 0px;
    width: 100%;
    padding: 0px 5%;
}
.title-container .title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
    flex-direction: column;
}
.title-container .title p{
    width: 80%;
    line-height: 28px;
    text-align: justify;
    color:rgba(0, 0, 0, 0.87)
}
.courses{    
    width: 100%;        
    display: flex;
    align-items: center;
    justify-content: start;
}
.courses .list{
    width: 100%;
    padding: 30px 5%;
    display: flex;    
    align-items: center;
    justify-content: center;
    gap:10px 20px;
    flex-wrap: wrap;
    background: #F0F1F3;
}

.courses .list img{
    width: 100%;
    height: 280px;
    object-fit: contain;
    transition: all ease 0.4s;
}
.courses .list .card{
    width: 28%;
    background: white;
    height: 400px;
    border:2px solid rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap:10px;
    overflow: hidden;
}
.courses .list .card:hover  img{
    transform: scale(1.1);
    cursor: pointer;
}
.courses .list .card .card-action{
    width: 100%;
    height: 150px;
    background: rgba(0, 0, 0, 0.87);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    color:white;
}
.courses .list .card .card-action p{
    font-size: 14px;
}
.courses .list .card .card-action button{
    padding: 10px 20px;
    border: 1px solid white;
    border-radius: 4px;
    background: transparent;
    color:white;
    cursor: pointer;
    transition: all ease 0.4s;
}
.courses .list .card .card-action button:hover{
    background: white;
    color:black
}
.courses .list .card .card-action button:hover i{
    transform: translateX(10px);
}
.courses .list .card .card-action button i{
    margin-left: 8px;
    transition: all ease 0.4s;
}
.courses .list .card .card-footer .title{
    font-size: 18px;

}
.courses .text-center{
    width: 100%;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
}
.overview{
    width: 100%;      
    padding: 30px 5%;
}
.overview .title{
    width: 100%;    
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap:20px;
}
.course-content1{
    width: 100%;    
    padding: 20px 5%;   
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:15px;
    background: #F0F1F3;

}
.course-content1 .video{
    width: 70%;
    height: 100%;    
    border-radius: 2px;
    border: 2px solid rgba(0, 0, 0, 0.87);
    overflow: hidden;
}
.course-content1 .video .video-responsive{
    width: 100%;
    height: 100%;
}
.course-content1 .video .video-responsive iframe{
    width: 100%;
    height: 100%;
}
.course-content1 .chapters{
    width: 30%;
    height: 100%;
    border-radius: 2px;
    border:2px solid rgba(0, 0, 0, 0.87);
    overflow: hidden;    
    position: relative;
}
.course-content1 .chapters .title{
    width: 100%;
    height: 50px;
    display: grid;
    place-items: center;
    background: rgba(0, 0, 0, 0.87);
    position: sticky;
}
.course-content1 .chapters .list{
    width: 100%;
    overflow: hidden;
    overflow-y: auto;  
    padding: 10px 15px;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap:10px;
}
.course-content1 .chapters .list .items{
    width: 100%;        
}
.course-content1 .chapters .list .items button{
    padding: 8px 10px;
    width: 100%;
    outline: none;
    text-align: start;
    cursor: pointer;
    transition: all ease 0.4s;    
    border: 2px solid rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
}
.course-content1 .chapters .list .items .selected{
    padding: 8px 10px;
    width: 100%;
    outline: none;
    text-align: start;
    cursor: pointer;
    transition: all ease 0.4s;   
    background: rgba(0, 0, 0, 0.87);; 
    border: 2px solid rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    white-space: nowrap; 
    color:white;
    overflow: hidden; 
    text-overflow: ellipsis; 
}
.course-content1 .chapters .list .items button i{
    margin-right: 10px;
}
.course-content1 .chapters .list .items button:hover{
    background: rgba(0, 0, 0, 0.87);
    color:white;
}
.course-content1 .chapters .title h2{
    font-size: 18px;
    color:white;
    font-weight: 599;
}
.video-responsive {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
}

.video-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hide-watch-button::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(255, 255, 255, 0); /* Transparent background */
}