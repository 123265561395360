
.background,
.container121{
  width: 100%;
  height: 100vh;
  background: #f0f1f3;
}
.slide-in{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: blueviolet;
  transform-origin: bottom;
}
.slide-out{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: blueviolet;
  transform-origin: top;
}
.container121{
  opacity: 0; 
  display: none;
}
@media screen and (max-width:900px) {
    .background{
      opacity: 0;
      display: none;
    }
    .container121{
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .container121 .card{
      width: 380px;
      height: 450px;
      background: white;
      border-radius: 8px;
      box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.25);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      gap:10px;
      overflow: hidden;
    }
    .container121 .card .title{
      width: 100%;
      background: blueviolet;
      height: 200px;
      color:white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .container121 .card .title i{
      font-size: 4.5em;
    }
    .container121 .card .action-text{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap:20px;
      padding: 10px 0px;
      font-size: 1.04em;
      font-weight: 600;
      color:goldenrod;
    }
    .container121 .card .action-text p{
      padding: 0px 20px;
      text-align: justify;
      line-height: 28px;
      color:black;
      font-size: .98em;
      font-weight: normal;
    }
}