@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
*{  
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Poppins,sans-serif;  
}
body {
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
  user-select: none;         /* Non-prefixed version */
}

::selection {
  background: transparent; /* No background color */
  color: inherit;          /* Inherit the text color */
}

::-moz-selection {
  background: transparent; /* No background color */
  color: inherit;          /* Inherit the text color */
}
/* For Webkit browsers */
::-webkit-scrollbar {
  width: 0px; /* Hide scrollbar */
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

/* On hover */
.scrollable-div:hover::-webkit-scrollbar {
  width: 8px;
}

.scrollable-div:hover::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.scrollable-div:hover::-webkit-scrollbar-thumb:hover {
  background: #555;
}
