.thank-you-message-container{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.67);
    display: flex;
    align-items: center;
    justify-content: center;
}
.thank-you-message{
    width: 500px;
    height: 350px;
    background: url('../assets/celebration.gif');
    background-position: center;
    background-size: cover;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.47);
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap:15px;
    transition: all ease-in 0.4s;
    animation: fade linear 0.5s;
}
@keyframes fade{
    0%{
        opacity: 0;
        transform: scale(.8);
    }
    50%{
        opacity: 0.7;
        transform: scale(1.15);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}
.thank-you-message h2{
    font-size: 1.4em;
    color:rgb(87, 6, 162)
}
.thank-you-message h2::first-letter{
    font-size: 1.401em;
}
.thank-you-message p{
    font-size: 0.9em;
    text-align: justify;
}
.thank-you-message button{
    padding: 10px 15px;
    margin-top: 10px;
    background: rgb(87, 6, 162);
    border: 2px solid rgb(87, 6, 162);
    color:white;
    outline: none;
    border-radius: 4px;
    cursor: pointer;
}