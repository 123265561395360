.faq-section {
    width: 100%;
    height: 650px;
    padding: 20px 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:0px;
    background: white;
    overflow: hidden;
}
.faq-section .banner{
    width: 50%;
    height: 100%;
    background: url('../assets/FAQ.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    filter: drop-shadow(0px 2px 10px rgba(0,0,0,0.24));
    position: sticky;
    top:0;
    left: 0;
}
.faq-section .info{
    width: 50%;
    height: 100%;
    padding: 0px 20px;
}
.faq-section .info h2{
    font-size: 1.36em;
    color:rgb(81, 8, 148);
    padding-bottom: 10px;
}
.faq-section .info h2::first-letter{
    font-size: 1.37em;
}
.faq-section .info .faq-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap:8px;    

}
.info .faq-container .faq-item{
    width: 100%;
    border: 3px solid blueviolet;
    border-radius: 5px;
    overflow: hidden;
    max-height: 120px;
    height: 45px;
    transition: all ease-in 0.4s;
}
.info .faq-container .faq-item-show{
    width: 100%;
    border: 3px solid blueviolet;
    border-radius: 5px;
    overflow: hidden;
    max-height: 120px;
    height: 100px;
    transition: all ease-in 0.4s;
}
.info .faq-container .faq-item .title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px;
    background: white;
    transition: all ease-in 0.4s;
}
.info .faq-container .faq-item-show .title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px;
    background: rgb(73, 5, 136);
    transition: all ease-in 0.4s;
}
.info .faq-container .faq-item h1{
    font-size: 0.8em;
    color:rgb(73, 5, 136);
    transition: all ease-in 0.4s;
}
.info .faq-container .faq-item i{
    font-size: 20px;
    color:rgb(73, 5, 136)
}
.info .faq-container .faq-item .answer{
    width: 100%;
    padding: 10px;
    font-size: 0.8em;
}
.info .faq-container .faq-item-show h1{
    font-size: 0.8em;
    color:#fff;
    transition: all ease-in 0.4s;
}
.info .faq-container .faq-item-show i{
    font-size: 20px;
    color:white;
}
.info .faq-container .faq-item-show .answer{
    width: 100%;
    padding: 10px;
    font-size: 0.8em;
}
  