.result-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.result-container .result-box {
    width: 60vw;
    max-height: 70vh;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.21);
    position: relative;
}
.result-box .loader{
    position: absolute;
    z-index: 100;
    width: 200px;
    height: 200px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:10px;
    border-radius: 4px;
    box-shadow:2px 3px 10px rgba(0, 0, 0, 0.51);
    padding: 0px 8px;
    background: #fff;
}
.loader .box{
    width: 90px;
    height: 90px;
    border: 8px solid #ddd;
    border-radius: 50%;
    border-top: 8px solid blue;
    transition: all ease-in 0.4s;
    animation: anim1 linear infinite alternate-reverse 1.5s;
    overflow: hidden;
}
.loader p{
    font-size: 0.85em;
    color:black;
    transition: all ease-in 0.2s;
    padding: 0px 10px;
    text-align: center;
    animation: anim2 linear infinite alternate-reverse .5s;
    font-weight: 450;
}
@keyframes anim1{
    0%{
        rotate: 0deg;
    }
    100%{
        rotate: 360deg;
    }
}
@keyframes anim2{    
    100%{
        font-weight: 600;
        color:blue;
    }
}
.result-box .title{
    width: 100%;
    height: 60px;
    display: grid;
    place-content: center;
    color:blue;
}
.result-box .info{
    width: 90%;
    margin: 0px auto;
    padding: 10px 30px;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-wrap: wrap;
    gap:15px 25px;
}
.result-box .info .info-field{
    width: 28%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap:5px;
}
.info .info-field label{
    font-weight: 550;
    font-size: 0.91em;
}
.info .info-field p{
    font-size: 0.97em;
}
.info .info-field .qualified{
    color:darkgreen;
    font-weight: 550;
}
.info .info-field .not-qualified{
    color:red;
    font-weight: 550;
}
.result-box .section-details{
    width: 100%;
    padding: 10px 20px;
}
.section-details .title-section{
    width: 100%;    
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    border: 1px solid blue;
}
.title-section .title{
    width: 100%;
    height: 40px;
    background: blue;
}
.title-section p{
    font-size: 1.01em;
    color:white;
}
.section-details table{
    width: 100%;
    padding: 5px 8px;
    border: none;
    border-collapse: collapse;
    cursor: pointer;
}
.section-details table thead{
    width: 100%;
    background: rgba(0, 0, 255, 0.286);
}
.section-details table thead tr,
.section-details table tbody tr{
    border-bottom: 1px solid blue;    
}
.section-details table tbody tr:hover td{
    font-weight: bold;
}
.section-details table thead th{
    font-size: 0.82em;
    font-weight: 450;
    padding: 8px;
}

.section-details table tbody td{
    padding: 5px;
    font-size: 0.75em;
    text-align: center;
    transition: all ease-in 0.25s;
    font-weight: 400;
}
.title-section table tr:nth-child(even){
    background: rgba(0, 0, 255, 0.286);
}
.result-box .action-footer{
    width: 100%;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:10px;
}
.result-box .action-footer h2{
    font-size: 1.3em;
    color:blue;
    font-weight: 550;
}
.result-box .action-footer button{
    padding: 10px 15px;
    background: blue;
    border: 2px solid blue;
    outline: none;
    border-radius: 4px;
    color:white;
    cursor: pointer;
}