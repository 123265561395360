.actual-container{
    width: 100%;
    height: 100vh;
    background: #F0F1F3;
    position: relative;
}
.actual-container .card{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 400px;
    height: 200px;
    background: white;
    box-shadow: 0px 2px 8px blue;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:20px;
}
.actual-container .card h2{
    width: 100%;
    font-size: 20px;
    text-align: center;
    font-weight: 599;
}
.actual-container .card button{
    padding: 10px 20px;
    background: blue;
    border-radius: 4px;
    color:white;
    outline: none;
    border:2px solid blue;
    cursor: pointer;
}
.actual-header{
    width: 100%;
    height: 60px;
    box-shadow: 0px 2px 8px black;
    background: white;
    padding: 0px 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.actual-header h2{
    font-size: 20px;
    background: linear-gradient(-5deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8));
    color:transparent;
    background-clip: text;
}
.actual-header button{
    padding: 8px 18px;
    outline: none;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.8);
    border: 2px solid rgba(0, 0, 0, 0.8);
    color:white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.actual-header button:disabled{
    padding: 8px 18px;
    outline: none;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
    border: 2px solid rgba(0, 0, 0, 0.8);
    color:black;
    cursor: not-allowed;
    transition: all 0.3s ease;
}
.actual-sections{
    width: 100%;
    margin: 20px 0px;
    height: 500px;
    padding: 20px 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:30px;
}
.actual-sections  .check{
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:15px;
}
.actual-sections .check input[type='checkbox']{
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #000;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
}
.actual-sections .check input[type="checkbox"]:checked {
    background-color: rgba(0, 0, 0, 0.8);
}
.actual-sections .check [type="checkbox"]:checked::after {
    content: '✓';
    transform: rotate(25deg);
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: -3px;
    left: 2px;
}
.actual-sections  .check p{
    font-size: 16px;
}
.actual-sections .instructions{
    width: 65%;
    height: 100%;
    border:2px solid rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    overflow: hidden;    
}
.actual-sections .instructions .title,
.actual-sections .sections .title{
    width: 100%;
    height: 50px;
    background: rgba(0, 0, 0, 0.8);
    color:white;
    display: grid;
    place-items: center;
}
.actual-sections .instructions .title h2,
.actual-sections .sections .title h2{
    font-size: 18px;
    font-weight: 599;
}
.actual-sections .instructions ul{
    padding: 30px 50px;
}
.actual-sections .instructions ul li{
    font-size: 15px;
    text-align: justify;
}
.actual-sections .sections{
    width: 45%;
    height: 100%;
    border:2px solid rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    overflow: hidden;
}
