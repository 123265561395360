.view-container{
    width: 100%;
    padding: 30px 5%;
    background: transparent;
    display: flex;
    align-items: start;
    justify-content: space-between;
}
.view-container .box{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap:5px;
}
.view-container .box label{
    font-size: 0.91em;
    font-weight: 550;
}
.view-container .box p{
    font-size: 0.88em;
}
.view-container .box select{
    padding: 5px;
    outline: none;
    border: 1px solid #ddd;
    background: #fff;
    color:black;
}
.solution-box{
    width: 100%;
    padding: 10px 5%;
    background: white;
    display: flex;
    align-items: start;
    justify-content: start;
    margin: 20px 0px;
}
.solution-box .question-box{
    width: 80%;    
}
.solution-box .question-sequence{
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    gap:15px 25px;
    padding: 10px 20px;
}
.solution-box .question-sequence button{
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 10%;
    transition: all ease-in 0.4s;
    cursor: pointer;
    border: 2px solid blueviolet;
    color:blueviolet;
    font-size: 1em;
    font-weight: 550;
}
.solution-box .question-sequence .active{
    width: 40px;
    height: 40px;
    background: blueviolet;
    border-radius: 50%;
    transition: all ease-in 0.4s;
    cursor: pointer;
    border: 2px solid blueviolet;
    color:white;
    font-size: 1em;
    font-weight: 550;
}
.view-sections{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:30px;
    padding: 10px 5%;
    background: whitesmoke;
}
.view-sections .btn{
    padding: 10px 20px;
    background: white;
    border: 2px solid blueviolet;
    outline: none;
    border-radius: 3px;
    cursor: pointer;
    transition: all ease-in 0.4s;
}
.view-sections .active{
    background: blueviolet !important;
    color:white;
    font-weight: bold;
    outline: none;
    border-radius: 50%;
    border: 2px solid blueviolet;
    cursor: pointer;
    transition: all ease-in 0.4s;
}
.questions{
    width: 100%;
    padding: 10px 5%;
}
.questions .box{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
}
.view-solution{
    width: 100%;
    padding: 20px 5%;
}
.view-solution .question{
    width: 100%;
    padding: 20px 0px;
}
.view-solution .options{
    width: 70%;
    padding: 20px 0px;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-wrap: wrap;
    gap:15px 25px;
}
.view-solution .options .option{
    width: 100%;
    padding: 10px 0px;
    display: flex;
    align-items: start;
    justify-content: start;
    gap:8px;
}
.view-solution .options .option .box{
    width: 25px;
    height: 25px;
    border-radius: 3px;
    border: 2px solid grey;;
}
.view-solution .options .option .selected{
    width: 25px;
    height: 25px;
    border-radius: 3px;
    border: 2px solid blueviolet !important;
    background: blueviolet !important;
    padding: 5px !important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color:white;
}
.view-solution .options .option .wrong{
    width: 25px;
    height: 25px;
    border-radius: 3px;
    border: 2px solid red !important;
    background: red !important;
    padding: 5px !important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color:white;
}

.view-solution .status{
    width: 70%;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.view-solution .status .box{
    display: flex;
    align-items: center;
    justify-content: start;
    gap:10px;
}
.view-solution .status .st{
    padding: 12px;
    border-radius: 3px;
    background: red;
    border-radius: 3px;
    width: 100px;
    text-align: center;
    color:white;
    font-weight: bold;
}
.view-solution .status .correct{
    padding: 12px;
    border-radius: 3px;
    background: green;
    border-radius: 3px;
    width: 100px;
    text-align: center;
    color:white;
    font-weight: bold;
}
.view-solution .your-answer{
    padding: 10px 0px;
    background: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:10px;
}