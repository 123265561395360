.header{
    width: 100%;
    height: 60px;
    background: blueviolet;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 5%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.87);    
}
.header .title{
    text-decoration: none;
    padding: 8px;
    background: white;
    font-size: 1.2rem;
    text-decoration: none;
    color:transparent;
    background-clip: text;
    -webkit-background-clip: text;
    font-weight: bold;
    letter-spacing: 1px;
    position: relative;
}
.header .actions{
    display: flex;
    align-items: center;
    justify-content: start;
    gap:50px;
}
.header .actions .active{
    text-decoration: none;
    font-size: 1rem;
    color:white;
    font-weight: 599.87;
    transition: all ease-out 0.4s;
    position: relative;
}
.header .actions .active::after{
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 100%;
    border-radius: 3px;
    height: 3px;
    background:linear-gradient(-110deg,whitesmoke,white);
    transition: all ease 0.4s;
}
.header .actions a{
    text-decoration: none;
    font-size: 1rem;
    color:white;
    font-weight: 599.87;
    transition: all ease-out 0.4s;
    position: relative;
}

.header .actions a::after{
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 0%;
    border-radius: 3px;
    height: 3px;
    background:linear-gradient(-110deg,whitesmoke,white);
    transition: all ease 0.4s;
}
.header .actions a:hover::after{
    width: 100%;
}
.header .actions button{
    padding: 8px 15px;
    background: transparent;
    outline: none;
    color:white;
    font-size: 14px;
    outline: none;
    border: 2px solid white;
    border-radius: 2px;
    cursor: pointer;
    transition: all ease-in 0.38s;
    font-weight: 550;
}
.header .actions .logout{
    padding: 8px 15px;
    background: crimson;
    outline: none;
    color:white;
    font-size: 14px;
    outline: none;
    border: 2px solid crimson;
    border-radius: 2px;
    cursor: pointer;
    transition: all ease-in 0.38s;
}

.container{
    width: 100%;
    height: 600px;
    background: #FFEFD6;    
    background-size: cover;
    background-position: center;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: start;    
    padding: 0% 5%;
}
.container .content{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap:15px;
}
.container .content .data{
    width:90%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap:15px;
}
.container .content .data h1{
    font-size: 30px;
    letter-spacing: 2px;
    color:blueviolet
}
.container .content .data h2 {
    font-size: 24px;
}
.container .content .data p{
    line-height: 30px;
    font-size: 16px;
    text-align: justify;
}
.container .content .data p strong{
    color:blueviolet;
    font-weight: 599;
}
.container .content .data button{
    margin-top: 20px;
    padding: 10px 20px;
    outline: none;
    border: 2px solid blueviolet;
    background: blueviolet;
    color:white;
    cursor: pointer;
    border-radius: 3px;
    transition: all ease 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
}
.container .content .data button:hover i{
    transform:translateX(10px)
}
.container .content .data button:hover{
    transform: scale(1.03);
}
.container .content .data button i{
    transition: all ease 0.4s;
}
.container .bg{
    width: 50%;
    height: 100%;
    background: url('../assets/background.avif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.container1{
    width: 100%;
    height: 150px;
    background: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: start;    
}
.container1 .item{
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:10px;
}
.container1 .item p{
    font-size: 18px;
    font-weight: bold;
    color:rgba(0, 0, 0, 0.87);
    font-family: "Roboto Flex";
}
.container1 .item p:nth-child(2){
    font-size: 22px;
    color:black;
}
.container1 .item:nth-child(1){
    background:lightsteelblue;
}
.container1 .item:nth-child(2){
    background:lightgreen;
}
.container1 .item:nth-child(3){
    background:lightcoral;
}
.container1 .item:nth-child(4){
    background:lightpink;
}
.container1 .item:nth-child(5){
    background:lightseagreen;
}
.container2{
    width: 100%;
    padding: 40px 5%;
    background: #F0F1F3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:20px;
}
.container2 .title h2{
    text-align: center;
    font-size: 30px;
    color:blueviolet
}
.container2 .item{
    width: 100%;
    height: 350px;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
    margin: 10px 0px;
}
.container2 .item-white{
    width: 100%;
    height: 350px;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
    background: white;
    margin: 10px 0px;
}
.container2 .item .img:nth-child(1){
    width: 50%;
    height: 100%;
    background:url('../assets/undraw_product_iteration_kjok.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.container2 .item .img:nth-child(2){
    width: 50%;
    height: 100%;
    background:url('../assets/image1.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.container2 .item .text,
.container2 .item-white .text{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap:15px;
}
.container2 .item .text p{
    line-height: 30px;
}
.container3{
    width: 100%;
    padding: 20px 5%;    
}
.container3 .title{
    width: 100%;
    padding: 20px 0px;    
}
.container3 .title h2{
    font-size: 32px;
    text-align: center;
    letter-spacing: 2px;
}
.container3 .companies{
    margin: 20px 0px;;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:20px 25px;
    flex-wrap: wrap;
}
.container3 .companies .company{
    width: 200px;
    height: 140px;
    border: 1px solid rgba(0, 0, 0, 0.87);
    display: flex;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.container3 .companies .company img{
    width: 150px;
    height: 120px;
    object-fit: contain;
    transition: all ease 0.4s;
}
.container3 .companies .company img:hover{
    cursor: pointer;
    transform: scale(1.1);
}
.testimonials{
    width: 100%;
    padding: 30px 5%;
    background: #F0F1F3;
    position: relative;    
}
.testimonials .title h2{
    text-align: center;
    font-size: 30px;
    letter-spacing: 2px;    
}
.testimonials .items{
    width: 900px;
    height: 400px;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;    
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.27),
                -2px -2px 5px rgba(0, 0, 0, 0.27);
}
.testimonials .items .item{
    width: 100%;
    height: 400px;
    border: 1px solid silver;
    border-radius: 4px;
    display: flex;
    align-items: start;
    justify-content: start;
    padding: 40px 20px;
    gap:20px;
    background: white;
    position: relative;
}
.testimonials .items .item img{
    width: 250px;
    height: 100%;
    object-fit: cover;
    transition: all ease 0.4s;
    border-radius: 5px;    
}
.testimonials .items .item .content{
    width: calc(100% - 270px);
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start !important;
    gap:10px;
    transition: all ease 0.4s;
}
.testimonials .items .item .content .keywords{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
    gap:10px;
    transition: all ease 0.4s;
}
.testimonials .items .item .content h2{
    font-size: 20px;
    font-weight: 599;
    transition: all ease 0.4s;
    text-transform: uppercase;
}
.testimonials .items .item .content .feedback{
    width: 100%;
    font-size: 14px;
    height: 230px;
    transition: all ease 0.4s;
    line-height: 28px;
}
.testimonials .items .item .content .keywords .words{
    width: auto;
    padding: 8px;
    background: rgba(0, 0, 0, 0.87);
    border-radius: 3px;
    color:white;
    font-size: 13px;
    transition: all ease 0.4s;
}
.testimonials  .actions {
    position: absolute;
    top:50%;
    width: 110%;
    padding: 0px 280px;
    left: 0%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.testimonials  .actions button{
    width: 30px;
    height: 30px;    
    font-size: 18px;
    outline: none;
    border:none;
    border-radius: 10%;    
    color:rgba(0, 0, 0, 0.87);
    cursor: pointer;
    background: white;
}
.footer{
    width: 100%;    
    padding: 50px 5%;
    background: #F0F1F3;
    height: 300px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:30px;
}
.footer .image{
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap:10px;
}
.footer .image .logo{
    width: 80%;
    height: 200px;
    background: url('../assets/Whatsapp\ DP\ \(2\).png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.footer .image .name{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.footer .imp-links{
    width: 20%;   
    height: 100%; 
}
.footer .imp-links a{
    text-decoration: none;
    color:black;
}
.footer .imp-links p{
    font-size: 17px;
    font-weight: bold;
}
.footer .imp-links ul{
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap:12px;
    list-style: none;
}
.footer .imp-links ul li{
    font-size: 15px;
    cursor: pointer;
    transition: all ease 0.4s;
}
.footer .imp-links ul li i{
    margin-right: 8px;
    transition: all ease 0.4s;
}
.footer .imp-links ul li:hover{
    transform: translateX(10px);
}
.footer .imp-links ul li:hover a{
    color:blueviolet;
    font-weight: 499;
}

.footer .connect p{
    font-size: 17px;
    font-weight: bold;
}
.footer .connect .item{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer .connect .item i{
    width: 10%;
    font-size: 25px;
    color:blueviolet;
}
.footer .connect .item .add{
    font-size: 14px;
    font-weight: 450;
    width: 90%;
}
.footer .connect{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    height: 100%;
    gap:10px;
}
.footer .form{
    width: 30%;
    height: 100%;
}
.footer .form p{
    font-size: 17px;
    font-weight: bold;
    color:blueviolet
}
.footer .form form{
    width: 100%;
    padding: 8px 12px;
    border: 2px solid blueviolet;
    border-radius: 4px;
    margin-top: 10px;
}
.footer .form form label{
    font-size: 0.9em;
    color:rgba(0, 0, 0, 0.87)
}
.footer .form form input{
    width: 100%;
    padding: 8px;
    margin-top: 7px;
    outline: none;
    border: 1px solid black;
    border-radius: 4px;
}
.footer .form form .desc{
    font-size: 15px;
    text-align: center;
    margin: 8px 0px;
    color:rgba(0, 0, 0, 0.87);
    font-weight: 599;
}
.footer .form form input[type='submit']{
    padding: 10px;
    width: 100%;
    background: blueviolet;
    color:white;
    outline: none;
    border:none;
    cursor: pointer;
}
.copyright{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: blueviolet;
    color:white;
}
.profile-container{
    width: 100%;
    margin: 50px 0px;
    padding: 40px 5%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:20px;
}
.profile-container .image-container{
    width: 25%;
    height: 350px;
    border-radius: 4px;
    box-shadow: 0px 0px 2px black;
    background: white;
    position: relative;    
}
.profile-container .image-container .circle{
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background: white;
    box-shadow: 0px 0px 2px black;
    position: absolute;
    top:-70px;
    left: 29%;
    padding: 5px;
}
.profile-container .image-container .circle img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
}
.profile-container .image-container .content{
    width: 100%;
    border-radius: 4px;
    height: 120px;
    background: white;   
    margin-top: 90px; 
    display: flex;
    align-items: start;
    justify-content: start;    
    flex-direction: column;
    gap:16px;
    padding: 0px 5%;
}
.profile-container .image-container .content .box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:10px;
}
.profile-container .image-container .content .box p:nth-child(1){
    width: 30%;
    font-weight: bold;
}
.profile-container .image-container .content .box p:nth-child(2){
    width: 80%;
}
.profile-container .detail-container{
    width: 70%;
    height: 350px;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 0px 2px black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:20px;
}
.profile-container .detail-container button{
    padding: 10px 35px;
    background: blueviolet;
    box-shadow: 1px 1px 2px black;
    border-radius: 3px;
    outline: none;
    border: none;
    cursor: pointer;
    color:white;
    font-size: 15px;
}
.profile-container .detail-container button:disabled{
    padding: 10px 35px;
    background: white;
    box-shadow: 1px 1px 1px blueviolet,
                -1px 0px 0px blueviolet,
                0px -1px 0px blueviolet;
    border-radius: 3px;
    outline: none;
    border: none;
    cursor: pointer;
    color:black;
    font-size: 15px;
    cursor: not-allowed;
}