.enroll-container{
    width: 100%;
    margin: 10px 0px;
    padding: 20px 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:20px;
    background: white;

}
.enroll-container .info{
    width: 50%;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap:20px;
}
.enroll-container .info h2{
    color:rgb(100, 3, 190);
}
.enroll-container .info h3{
    color:blueviolet;
}
.enroll-container .info p{
    width: 90%;
    font-size: 1.01em;
    line-height: 30px;
    text-align: justify;
    color:rgba(0, 0, 0, 0.81)
}
.enroll-container .banner{
    width: 50%;
    height: 350px;
    background: url('../assets/join.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.enroll-info{
    width: 100%;
    height: 210px;
    padding: 0px 5%;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:10px;    
}
.enroll-info .card{
    width: 20%;
    height: 100%;
    background: white;
    border-radius: 8px;
    transition: all ease-in 0.4s;
    cursor: pointer;
    overflow: hidden;
}
.enroll-info .card .title{
    width: 100%;
    height: 100px;
    background: rgba(137, 43, 226, 0.442);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.01em;
    color:black;
}
.enroll-info .card .title i{
    transition: all ease-in 0.3s;
}
.enroll-info .card .title i:hover{
    filter:drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.21));
}
.enroll-info .card h2{
    font-size: 1.01em;
    padding: 5px 0px;
    text-align: center;
}
.enroll-info .card ul{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 2px 40px;
    list-style-type: disc;
    align-items: start;
    justify-content: start;
}
.enroll-info .card ul li{
    font-size: 0.82em;   
}
.enroll-info .card ul .check{
    display: flex;
    align-items: center;
    justify-content: start;
    gap:8px;
}
.enroll-info .card ul .check p:nth-child(1){
    font-weight: 450;
    text-decoration: line-through;
}
.enroll-info .card ul .check p:nth-child(2){
    font-weight: 750;
}
.enroll-info .card:hover{
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.31);
}
.enroll-form{
    width: 100%;
    height: 650px;
    background: #f0f1f3;
    padding: 20px 5%;
    display: flex;
    align-items: start;
    justify-content: start;
    gap:20px;
}
.enroll-form .card1{
    width: 19.5%;
    height: 200px;
    background: white;
    border-radius: 8px;   
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.21);
    overflow: hidden;
}
.enroll-form .card1 .title{
    width: 100%;
    height: 110px;
    background: rgba(137, 43, 226, 0.442);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5em;
}
.enroll-form .card1 .data{
    width: 100%;
    height: calc(100% - 110px);
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:5px;
}
.enroll-form .card1 .data p{
    font-weight: 550;
}
.enroll-form .card1 .data p:nth-child(1){
    font-size: 1.14em;
}
.enroll-form form{
    width: 80%;
    height: 100%;
}
.enroll-form form .title{
    padding: 10px;    
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap:10px;
}
.enroll-form form .title h2{
    font-size:  1.35em;
    color:rgb(97, 12, 177);
}
.enroll-form form .title p{
    font-size: 0.91em;
    width: 85%;
    text-align: justify;
    line-height: 28px;
}
.enroll-form .form-container{
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:15px 20px;
    flex-wrap: wrap;
}
.enroll-form form .form-container .input-field{
    width: 28%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap:5px;
}

.enroll-form form .form-container .input-field label{
    font-weight: 450;
    font-size: 0.85em;
}
.form-container .input-field label span{
    color:red;
}
.form-container .input-field input,
.form-container .input-field select{
    width: 100%;
    padding: 8px;
    border: 2px solid #ddd;
    outline: none;
    border-radius: 3px;
    transition: all ease-in 0.4s;    
}
.form-container .input-field input:disabled{
    background: white;
    cursor: no-drop;
    color:black;
    font-weight: bold;
}
.form-container .input-field input:focus,
.form-container .input-field select:focus{
    border: 2px solid blue;
    box-shadow: 3px 3px 8px rgba(0, 0, 255, 0.21);
}
.form-container .input-field button{
    padding: 10px 20px;
    margin-top: 25px;
    background: transparent;
    border: 2px solid blue;
    border-radius: 4px;
    color:blue;
    cursor: pointer;
    transition: all ease-in 0.4s;
}
.form-container .input-field button:hover{
    background: blue;
    color:white;
}
.form-container .error-field{
    width: 100%;
}
.form-container .error-field p{
    font-size: 1.05em;
    font-weight: 550;
    color:red;
}
form .form-container input[type='submit']{
    padding: 10px 20px;
    background: blueviolet;
    border: 2px solid blueviolet;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    color:white;
    margin-top: 10px;
    font-weight: bold;
}