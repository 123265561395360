.placement-container{
    margin: 20px 0px;
    width: 100%;
    padding: 20px 5%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:0;
}
.placement-container .info{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap:0.7em;
    height: 400px;
}
.placement-container .info h2{
    color:rgb(101, 6, 189);
    width: 55%;    
}
.placement-container .info h2::first-letter,
.placement-services .title h2::first-letter,
.m-container .title h2::first-letter,
.testomonial .info h2::first-letter,
.placement-action h2::first-letter{
    font-size: 1.3em;    
}
.placement-container .info p{
    font-size: 0.92em;
    color:rgba(0, 0, 0, 0.85);
    line-height: 30px;
    text-align: justify;
}
.placement-container .info .action{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:20px;
}
.placement-container .info .assistance{
    width: 100px;
    height: 100px;
    background: url('../assets/assistance.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.placement-container .info button{
    padding: 10px 25px;
    background: transparent;
    color:blueviolet;
    border-radius: 7px;
    cursor: pointer;
    outline: none;
    border: 2px solid blueviolet;
    font-size: 1.01em;
    margin-top: 10px;
    font-weight: 550;
    transition: all ease-in 0.44s;
}
.placement-container .info .offer{
    margin-top: 10px;
    padding: 8px 10px;
    background: rgba(0, 0, 0, 0.85);
    border-radius: 4px;
    text-transform: uppercase;
    text-decoration: none;
    border: 2px solid black;  
    font-weight: 500;  
    position: relative;
    transition: all 1s;    
    letter-spacing: 2px;
    z-index: 1;
    overflow: hidden;
    color:white;
}
.placement-container .info .offer::after{
    content: '';
    position: absolute;
    width: 0%;
    height: 100%;
    background: linear-gradient(45deg,rgb(90, 4, 171),blue,blueviolet);
    left: -50px;
    top: 0;
    z-index: -1;
    transform: skew(120deg);
    animation: anim2 linear infinite 2.5s;    
}
@keyframes anim2{
    0%{
        width: 0%;
        color:black !important;
    }
    50%{
        width: 150%;
        
    }
    100%{
        width: 0%;        
    }
}
.placement-container .info button:hover{
    box-shadow: 0px 10px 15px rgba(137, 43, 226, 0.25),
                inset 240px 0px 0px blueviolet;
    color:white;
}
.placement-container .banner{
    width: 50%;
    height: 400px;
    background: url('../assets/placement.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.placement-services{
    width: 100%;
    padding: 15px 5%;
    background: #f0f1f3;
}
.placement-services .title{
    width: 100%;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:10px;
}
.placement-services .title h2{
    font-size: 1.94em;
    color:rgb(105, 7, 197);
}
.placement-services .title p{
    text-align: center;
    width: 60%;
    line-height: 30px;
}
.placement-services .card-container{
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:20px 25px;
    flex-wrap: wrap;
}
.placement-services .card-container .card{
    width: 30%;
    height: 300px;
    background: white;
    border-radius: 7px;    
    transition: all ease-in 0.35s;
    cursor: pointer;
    overflow: hidden;
}
.card-container .card .card-title{
    width: 100%;
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:25px;
    background: rgba(137, 43, 226, 0.45);
}
.card-container .card .card-title i{
    font-size: 2.6em;
    transition: all ease-in 0.5s;
}
.card-container .card .card-title h2{
    font-size: 1.2em;
}
.card-container .card p{
    width: 100%;
    padding: 10px 20px;
    text-align: justify;
    line-height: 30px;
}
.placement-services .card-container .card:hover{
    box-shadow: 5px 5px 10px rgba(0,0,0,0.3);
    transform: scale(1.02);
}
.m-container{
    width: 100%;
    padding: 10px 5%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    gap:30px;
    background: white;
}
.m-container .title{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:20px;
}
.m-container .title h2{
    font-size: 1.85em;
    color: rgb(101, 6, 189);
}
.m-container .title p{
    text-align: justify;
    line-height: 30px;
}
.m-container .i-container{
    width:  100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;
    padding: 10px 0px;
}
.m-container .i-container img{
    width: 120px;    
    height: 100px;
    aspect-ratio: 3/2;
    object-fit: contain;
}
.m-container .i-container img:first-child{
    margin-left: 30px;
}
.testomonial{
    width: 100%;
    padding: 20px 5%;
    background: #f0f1f3;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:20px;
    overflow: hidden;    
    height: 550px;    
    position: relative;
}
.testomonial .info{
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap:20px;
}
.testomonial .info h2{
    color:rgb(88, 3, 167);
}
.testomonial .info p{
    width: 100%;
    line-height: 30px;
    text-align: justify;
    font-size: 0.95em;
}
.testomonial .test{
    width: 700px;
    height: 100%;    
    position: relative;
}
.testomonial .test .test-container{
    width: 100%;
    height: 100%;
    position: relative;
    overflow-x: auto;
    scroll-behavior: smooth; 
    white-space: nowrap;
}
.testomonial .test img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.testomonial .btn-left:hover,
.testomonial .btn-right:hover{
    color:blueviolet;
 }
.testomonial .btn-right{
    position: absolute;
    top:40%;
    padding: 10px;
    background: transparent;
    outline: none;
    border: none;
    font-size: 1.83em;
    cursor: pointer;
    transition: all ease-in 0.4s;
    right: -1%;
}
.testomonial .test .btn-left{
    position: absolute;
    top:40%;
    padding: 10px;
    background: transparent;
    outline: none;
    border: none;
    font-size: 1.83em;
    cursor: pointer;
    transition: all ease-in 0.4s;
    left: 1%;
}
.placement-action{
    width: 100%;
    padding: 20px 5%;
    background: #fcfcfe;
    display: flex;    
    align-items: start;
    justify-content: start;
    gap:15px;
}
.placement-action .element1{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap:15px;
    height: 300px;
}
.placement-action .element2{
    width: 30%;
    height: 300px;
    background: url('../assets/suraj_best_luck.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    filter: saturate(150%) drop-shadow(1px 1px 10px rgba(137, 43, 226, 0.293));
    transition: all ease-in 0.34s;
    cursor: pointer;
}

.placement-action .element1 h2{
    color:rgb(101, 6, 189)
}
.placement-action .element1 p:nth-child(even){
    font-size: 0.98em;
    color:blueviolet;
    font-weight: 550;
}
.placement-action .element1 p{
    font-size: 1.01em;
    line-height: 28px;
    text-align: justify;
    width: 80%;
}
.placement-action .action{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:20px;
}
.placement-action .action .assistance{
    width: 100px;
    height: 100px;
    background-color: transparent;
    background: url('../assets/assistance.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.placement-action .action .btn{
    padding: 10px 25px;
    background: transparent;
    color:blueviolet;
    border-radius: 7px;
    cursor: pointer;
    outline: none;
    border: 2px solid blueviolet;
    margin-top: 10px;
    font-weight: 550;
    transition: all ease-in 0.44s;
}
.placement-action .action .btn-fill{
    padding: 10px 25px;
    background: blueviolet;
    color:white;
    border-radius: 7px;
    cursor: pointer;
    outline: none;
    border: 2px solid blueviolet;
    margin-top: 10px;
    font-weight: 550;
    transition: all ease-in 0.44s;
}
.placement-action .action .btn:hover{
    box-shadow: 0px 10px 15px rgba(137, 43, 226, 0.25),
                inset 240px 0px 0px blueviolet;
    color:white;
}