.login-information{
    width: 100%;
    margin: 20px 0px;
    padding: 20px 5%;
    height: 290px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:20px;
}
.login-information h2{
    font-size: 25px;
    color:blueviolet;
}
.login-information button{
    padding: 10px 20px;
    border: 2px solid blueviolet;
    border-radius: 3px;
    cursor: pointer;
    color:blueviolet;
    transition: all ease-in 0.4s;
}
.login-information button:hover{
    background: blueviolet;
    color:white;
}
.exam-category{
    width: 100%;
    padding: 20px 5%;
    background: #F0F1F3;
}
.exam-category .back{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:10px;    
}
.exam-category .back i{
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}
.exam-category .back p{
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
}
.exam-category .title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;    
}
.exam-category .title h2{
    font-size: 20px;
    position: relative;
}
.exam-category .title h2::before{
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -5px;
    height: 4px;
    background: black;
    border-radius: 50%;
}
.exam-category .category{
    padding: 50px 0px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:20px 25px;
    flex-wrap: wrap;
    background: #F0F1F3;
}
.exam-category .category .card{  
    height: 300px;
    background: white;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 2px 5px black;
}
.exam-category .category .card .card-title1{
    width: 100%;
    height: 230px !important;
    background: blueviolet;
    display: grid;
    place-content: center;
    color:white !important;
}
.exam-category .category .card .card-title1 h2{
    font-weight: 599;
    font-size: 20px;
}
.exam-category .category .card .card-title{
    width: 100%;
    height: 80px;
    padding: 10px 3%;
}
.exam-category .category .card .card-title h2{
    font-size: 20px;
    color:black;
    font-weight: 699;
}
.exam-category .category .card .card-title p{
    color:rgba(0, 0, 0, 0.628);
    font-size: 14px;
    margin-top: 5px;
}
.exam-category .category .card .card-footer{
    width: 100%;
    padding: 10px 8px;    
    height: calc(100% - 230px);
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.exam-category .category .card .card-footer i{
    margin: 0px 5px !important;
    color:blueviolet
}
.exam-category .category .card .card-content{
    padding: 10px 3%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap:15px;
    width: 100%;
}
.exam-category .category .card .card-content button{
    padding: 8px 15px;
    border-radius: 3px;
    outline: none;
    background: blueviolet;
    border:2px solid blueviolet;
    color:white;
    cursor: pointer;
}
.exam-category .category .card .card-content button:disabled{
    padding: 8px 15px;
    outline: none;
    border:1px solid blueviolet;
    background: rgba(137, 43, 226, 0.828);
    cursor: no-drop;
    border-radius: 2px;
    color:white;
}
.exam-category .category .card .card-content .box{
    display: flex;
    align-items: center;
    justify-content: start;
    gap:10px;
    font-size: 14px;
    width: 100%;
    
}
.exam-category .category .card .card-content .box i{
    font-size: 18px;
    width: 8%;
}
.exam-category .category .card .card-content .active1{
    width: 100px;
    padding: 5px 10px;
    background: green;
    font-weight: bold;
    border-radius: 4px;
    color:white;
    font-size: 13px;
    text-transform: uppercase;
    text-align: center;
}
.exam-category .category .card .card-content .expired{
    width: 100px;    
    text-align: center;
    padding: 5px 10px;
    background: red;
    font-weight: bold;
    border-radius: 4px;
    color:white;
    font-size: 13px;
    text-transform: uppercase;
}
.exam-category .category .card .card-footer button{
    padding: 5px 15px;
    outline: none;
    border:1px solid blueviolet;
    background: blueviolet;
    cursor: pointer;
    border-radius: 2px;
    color:white;
    cursor: pointer;
}
.exam-category .category .card .card-footer button:disabled{
    padding: 5px 15px;
    outline: none;
    border:1px solid blueviolet;
    background: rgba(137, 43, 226, 0.828);
    cursor: no-drop;
    border-radius: 2px;
    color:white;
}