.test-container{
    width: 100%;
    height: 100%;
    background: #f1f1f1;
}
.test-container .card{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 400px;
    height: 200px;
    background: white;
    box-shadow: 0px 2px 8px blue;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:20px;
}
.test-container .card h2{
    width: 100%;
    font-size: 20px;
    text-align: center;
    font-weight: 599;
}
.test-container .card button{
    padding: 10px 20px;
    background: blue;
    border-radius: 4px;
    color:white;
    outline: none;
    border:2px solid blue;
    cursor: pointer;
}
.test-container .test-header{
    width: 100%;
    height: 60px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.test-container .test-header .time{
    width: 90px;
    height: 35px;
    background: rgba(0, 0, 255, 0.242);
    border-radius:25px;
    display: grid;
    place-content: center;    
    font-size: 1.2em;
    font-weight: bold;
    font-family: 'Open Sans';
    transition: all ease-in 0.3s;
    
}
.test-container .test-header .time-red{
    width: 90px;
    height: 35px;
    background: rgba(0, 0, 255, 0.242);
    color:rgb(157, 9, 9);
    border-radius:25px;
    display: grid;
    place-content: center;
    font-size: 1.2em;
    font-weight: bold;
    font-family: 'Open Sans';
    animation: blink linear infinite alternate 0.5s;
}
@keyframes blink{
    0%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
}
.test-container .section-details{
    width: 100%;
    padding: 0px 2%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.test-container .section-details h2{
    font-size: 1.01em;
    font-weight: 510;
}
.test-container .question-details{
    width: 98%;
    height: calc(100% - 180px);    
    background: white;
    margin: 0px auto;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.242);
    display: flex;
    align-items: center;
    justify-content: center;
    gap:0px;
}
.question-details .question{
    width: 60%;
    height: 100%;
    border-right: 2px solid #ddd;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap:15px;
    padding: 20px 25px;
}
.question-details .question .sequence{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.question-details .question .sequence .data{
    display: flex;
    align-items: center;
    justify-content: start;
    gap:8px;
}
.question .sequence .data p{
    font-size: 0.9em;
    font-weight: 550;
}
.question .sequence  .difficulty{
    font-size: 0.9em;
    font-weight: 550;
    color:crimson;
}
.question-details .option{
    width: 40%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    border-left: 2px solid #ddd;
    padding: 20px 25px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap:20px;    
}
.question-details .option form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap:15px;
    height: calc(100% - 100px);
    overflow: hidden;
    overflow-y: auto;
}
.question-details .option form .item label{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:5px;
    cursor: pointer;
}
.question-details .option form .item input[type='radio']{
    appearance: none;
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid #000;
    border-radius: 4px;
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
}
.question-details .option form .item input[type="radio"]:checked {
    background-color: blue;
    border: 2px solid blue;
}
.question-details .option form .item input[type="radio"]:checked + p{
    font-weight: 550;
    color:blue;
}
.question-details .option form .item input[type="radio"]:checked::after {
    content: '✓';
    transform: rotate(25deg);
    color: #fff;
    font-size: 1.1em;
    position: absolute;
    top: -3px;
    left: 1px;
}
.question-details .option form .item p{
    font-size: 0.91em;
}
.question-details .option form .item{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:10px;
}
.question-details .option .title{
    font-size: 0.91em;
    color:rgba(0, 0, 0, 0.842);
    font-weight: 550;
}
.question-details .option .action-buttons{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.question-details .option .action-buttons button{
    padding: 8px 20px;
    cursor: pointer;
    color:white;
    background: #111;
    outline: none;
    border: none;
    border-radius: 4px;
    transition: all ease-in 0.4s;
    font-weight: 410;
}
.question-details .option .action-buttons button:nth-child(1){
    background: transparent;
    color:crimson;
    border: 2px solid crimson;
}
.question-details .option .action-buttons button:nth-child(1):hover{
    background: crimson;
    color:white;
    border: 2px solid crimson;
}
.question-details .option .action-buttons button:nth-child(1):disabled{
    background: rgba(220, 20, 60, 0.448);
    cursor: no-drop;
}
.question-details .option .action-buttons button:nth-child(2){
    background: blue;   
    border: 2px solid blue;
}
.question-details .option .action-buttons button:nth-child(2):disabled{
    background: rgba(0, 0, 255, 0.448);
    color:black;
    cursor: no-drop;
}
.question-details .actual-question{
    width: 100%;
    height: calc(100% - 100px);
    overflow: hidden;
    overflow-y: auto;
}
.question-details .actual-question p:nth-child(1){
    font-size: 0.9em;
    color:rgba(0, 0, 0, 0.842);
    font-weight: 550;
}
.question-details .actual-question p:nth-child(2){
    text-align: justify;
    margin-top: 10px;
}
.question-details .acknowledge{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.question-details .acknowledge .question-action{    
    display: flex;
    align-items: center;
    justify-content: start;
    gap:20px;
}
.acknowledge .question-action .item{
    display: flex;
    align-items: center;
    justify-content: start;
    gap:10px;
}
.acknowledge .question-action h3{
    font-size: 1.01em;
    font-weight: 500;
}
.acknowledge .question-action .item p{
    background: black;
    color:white;
    padding: 3px 10px;
    border-radius: 4px;
}
.acknowledge .question-action .item:nth-child(1) h3{
    color:crimson
}
.acknowledge .question-action .item:nth-child(2) h3{
    color:blue
}
.acknowledge .question-action .item:nth-child(1) p{
    background:crimson
}
.acknowledge .question-action .item:nth-child(2) p{
    background:blue
}
.question-details .acknowledge .question-action .item{
    display: flex;
    align-items: center;
    justify-content: start;
    gap:8px;
}
.acknowledge .company-tags{
    display: flex;
    align-items: center;
    justify-content: start;
    gap:8px;
}
.acknowledge .company-tags p{
    padding: 3px 8px;
    background: rgba(0, 0, 255, 0.426);
    border-radius: 3px;
    font-size: 0.87em;
    font-weight: 550;
}
.test-container .question-footer{
    width: 100%;
    height: 50px;
    background: white;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 2%;
}
.test-container .question-footer p{
    font-size: 0.82em;
    font-weight: 510;
}